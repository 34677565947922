import clsx from 'clsx';
import { BadgeProps } from './types';
import { twMerge } from 'tailwind-merge';

export const Badge = (props: BadgeProps) => {
  const { children, className } = props;

  return (
    <div
      className={clsx(
        !className
          ? 'absolute top-0 -end-2 text-center text-xs rounded-full cursor-default bg-primary text-gray-500'
          : className
      )}
    >
      {children}
    </div>
  );
};
