'use client';

import { Fragment, useMemo } from 'react';
import reactStringReplace from 'react-string-replace';
import { useLocalization } from '@akinon/next/hooks';

interface TransProps {
  i18nKey: string;
  components?: {
    [key: string]: React.ReactElement;
  };
}

export const Trans = ({ i18nKey, components }: TransProps) => {
  const { t } = useLocalization();
  const value = t(i18nKey);

  const replacedValue = useMemo(() => {
    if (!components) {
      return value;
    }

    let text;

    for (const [k] of Object.entries(components)) {
      text = reactStringReplace(
        text ?? value,
        new RegExp(`(<${k}/>|<${k} />)`),
        (_match, i) => (
          <Fragment key={`trans-${k}-${i}`}>{components[k]}</Fragment>
        )
      );
    }

    return text;
  }, [value, components]);

  return <>{replacedValue}</>;
};
