import { ComponentPropsWithoutRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { buildCDNUrl } from '@akinon/next/utils';
import { IMAGE_OPTIONS } from 'utils';

interface ImageProps
  extends Omit<ComponentPropsWithoutRef<'img'>, 'srcSet' | 'sizes'> {
  imageKey?: keyof typeof IMAGE_OPTIONS;
  sources?: Array<ComponentPropsWithoutRef<'source'>>;
  fill?: boolean;
  objectCover?: boolean;
  local?: boolean;
}

export const Image = (props: ImageProps) => {
  const {
    imageKey,
    sources = [],
    fill = false,
    objectCover = true,
    local = false,
    src,
    alt,
    width,
    height,
    className,
    loading = 'lazy',
    decoding = 'async',
    ...restImage
  } = props;

  const { _src, _width, _height, _sources } = useMemo(() => {
    if (imageKey) {
      const imageOptions = IMAGE_OPTIONS[imageKey];

      return {
        _src: buildCDNUrl(src, imageOptions),
        _width: imageOptions.width,
        _height: imageOptions.height,
        _sources: imageOptions.sources
      };
    }

    return { _src: src, _width: width, _height: height };
  }, [src, width, height, imageKey]);

  return (
    <picture>
      {_sources?.map((_source, index) => {
        const { media, options } = _source;
        const { width, height } = options;

        let srcSet = src;

        if (options) {
          srcSet = buildCDNUrl(srcSet, options);
        }

        return (
          <source
            key={index}
            media={`(${media})`}
            srcSet={`/_next/image?url=${encodeURIComponent(
              srcSet
            )}&w=1080&q=75`}
            width={width}
            height={height}
          />
        );
      })}
      <>{...sources}</>
      <img
        src={
          local
            ? src
            : `/_next/image?url=${encodeURIComponent(_src)}&w=1080&q=75`
        }
        alt={alt}
        width={_width}
        height={_height}
        loading={loading}
        decoding={decoding}
        className={twMerge(
          clsx({ 'w-full h-auto': fill }, { 'object-cover': objectCover }),
          className
        )}
        {...restImage}
      />
    </picture>
  );
};
