'use client';

import { useLocalization } from '@akinon/next/hooks/use-localization';
import { useAppSelector } from '@akinon/next/redux/hooks';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { RootState } from 'redux/store';
import settings from 'settings';

interface CommonDataLayerProps {
  pageType?: string;
  pageCategory?: string;
}

export const CommonDataLayer = (props: CommonDataLayerProps) => {
  const { pageType, pageCategory } = props;

  const { data: session, status } = useSession();
  const { locale, currency } = useLocalization();
  const localeValue = settings.localization.locales.find(
    (locale_) => locale_.value === locale
  ).apiValue;

  const preOrder = useAppSelector(
    (state: RootState) => state.checkout.preOrder as any
  );

  useEffect(() => {
    const email = session?.user?.email ?? preOrder?.user_email ?? '';

    if (email) {
      window.dataLayer.push({
        pageType: pageType ?? '',
        pageCategory: pageCategory ?? '',
        storeLanguage: localeValue ?? '',
        storeCurrency: currency ?? '',
        storeCountry: locale?.split('-')[1] ?? '',
        userId: session?.user?.pk ?? '',
        userType: status === 'authenticated' ? 'Registered user' : 'Anonymous',
        loggedInStatus: status === 'authenticated' ? 'Yes' : 'No',
        hashedEmail: session?.user?.hashedEmail ?? '',
        userEmail: email
      });
    }
  }, [
    currency,
    locale,
    localeValue,
    pageCategory,
    pageType,
    session,
    status,
    preOrder
  ]);

  return null;
};
