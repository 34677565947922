import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

interface ActionState {
  countryCodes: [
    {
      value: string;
      label: string;
      flag: string;
    }
  ];
  selectedCountry: {
    value: string;
    label: string;
    flag: string;
  };
}

const initialState: ActionState = {
  countryCodes: [{ value: '+971', label: '+971', flag: 'bae' }],
  selectedCountry: { value: '+971', label: '+971', flag: 'bae' }
};

export const createData = createSlice({
  name: 'country-data',
  initialState,
  reducers: {
    setCountryCode(state, action) {
      state.selectedCountry = action.payload;
    }
  }
});

export const { setCountryCode } = createData.actions;

export const countryCodeReducer = createData.reducer;

export function makeStore() {
  return configureStore({
    reducer: { action: countryCodeReducer }
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
