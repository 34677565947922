import { Icon } from './';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { Image } from '@akinon/next/components/image';
import { useEffect, useState } from 'react';

//redux
import { createData } from 'redux/country-code-store';
//redux

export const PhoneInput = ({ attributes, label, value, state }) => {
  const country_codes = state?.getState()?.action?.countryCodes;
  const selectedCountry = state?.getState()?.action?.selectedCountry;

  const [inputValue, setInputValue] = useState(value);
  const [countryListHidden, setCountryListHidden] = useState(true);
  const inputClass = twMerge(
    clsx(
      'w-full h-[58px] text-[11px] font-sans text-primary-400 border px-4 placeholder:text-gray-600',
      'focus-visible:outline-none',
      attributes.className
    )
  );

  const setCountryCode = (item) => {
    state?.dispatch(createData.actions.setCountryCode(item));
    setCountryListHidden(true);
  };

  const onInputHandle = (event) => {
    const val = event.target.value;
    const regex = val.replace(/\D/g, ''); // remove any non-digit characters
    setInputValue(regex);
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        inputValue !== undefined &&
        inputValue !== null &&
        inputValue !== ''
      ) {
        const split = value
          .toString()
          .split(state.getState().action.selectedCountry?.value);
        setInputValue(split[1]);
      }
    }, 10);
  }, [value]);

  return (
    <div className="relative">
      <label
        className={clsx(
          'block text-[11px] uppercase font-serif text-primary-400 mb-5'
        )}
      >
        {label} {attributes.required && <span className="text-error">*</span>}
      </label>
      <div>
        <div
          className={clsx(
            'flex items-stretch',
            attributes.error &&
              'border border-error focus-within:border-transparent'
          )}
        >
          <div
            className="flex items-center gap-x-1 rtl:space-x-reverse mr-2 rtl:ml-2 rtl:mr-0 cursor-pointer"
            onClick={() => setCountryListHidden(!countryListHidden)}
          >
            <Image
              src={`/flags/${selectedCountry?.flag}.svg`}
              width={32}
              height={13}
              alt="logo"
              unoptimized
            />
            <span className="text-[11px] text-black-400 cursor-pointer pt-1">
              {selectedCountry?.label}
            </span>
            <Icon name="chevron-down" size={7} className="text-black-400" />
          </div>

          <ul
            className={`absolute w-36 top-24 ltr:left-0 rtl:rigt-0 border shadow-xl bg-gray-20 divide-y z-20 p-3 ${
              countryListHidden ? 'hidden' : 'block'
            }`}
          >
            {country_codes?.map((country_code, index) => (
              <li
                key={index}
                data-value={country_code.value}
                className="flex items-center text-sm space-x-2 rtl:space-x-reverse cursor-pointer"
                onClick={() => setCountryCode(country_code)}
              >
                <Image
                  src={`/flags/${country_code.flag}.svg`}
                  width={24}
                  height={24}
                  alt="logo"
                  unoptimized
                />
                <span>{country_code.label}</span>
              </li>
            ))}
          </ul>

          <input
            type="tel"
            {...attributes}
            placeholder="56XXXXXXX"
            value={inputValue ?? ''}
            mask="_"
            className={inputClass}
            onChange={(event) => onInputHandle(event)}
          />
        </div>
        {attributes.error && (
          <span className="mt-1 text-sm text-error">
            {attributes.error.message}
          </span>
        )}
      </div>
    </div>
  );
};
